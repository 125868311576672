import React, { Component } from 'react';

import { LINKS } from "../constants/links";
import SEO from '../components/SEO/SEO';


export default class extends Component {
	componentDidMount() {
		window.location.href = LINKS.VIMEO_VIDEO;
	}

	render() {
		const metaData = 'Sharekey Video';
		const description = 'We secure your business privacy';
		const {
			mainPageMetaImage,
			mainPageOgImage,
			mainPageTwitterImage
		} = this.props.data.data.edges[0].node;
		const { globalConfig } = this.props.data;

		return <SEO
			lang={'en'}
			title={metaData}
			description={description}
			ogDescription={description}
			twitterDescription={description}
			cover={mainPageMetaImage.file.url}
			ogCover={mainPageOgImage.file.url}
			twitterCover={mainPageTwitterImage.file.url}
			fbappid={globalConfig.fbappid}
			twitterUser={globalConfig.twitterUser}
			slugs={{
				en: '/',
				fr: '/fr',
				de: '/de'
			}}
			location={this.props.location}
		/>
	}
}

export const pageQuery = graphql`
    query VideoPaperQuery {
        globalConfig: contentfulGlobalConfig {
            id
            footer
            header
            fbappid
            twitterUser
        }
        data: allContentfulLandingPage(filter: { node_locale: { eq: "en-US" } }) {
            edges {
                node {
                    mainPageMetaTitle
                    mainPageMetaDescription
                    mainPageOgDescription
                    mainPageTwitterDescription
                    mainPageMetaImage {
                        file {
                            url
                        }
                    }
                    mainPageOgImage {
                        file {
                            url
                        }
                    }
                    mainPageTwitterImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
`;

